// extracted by mini-css-extract-plugin
export var sliderContainer = "home-module--slider-container--3xAF9";
export var slide = "home-module--slide--2r2f0";
export var slideContent = "home-module--slide-content--yUPvq";
export var bigBlockContent = "home-module--bigBlockContent--3sH-m";
export var bigBlockContentRight = "home-module--bigBlockContentRight--wdKKm";
export var bigBlockFigure = "home-module--bigBlockFigure--BMxuN";
export var bigBlockFigureHalf = "home-module--bigBlockFigureHalf--6U9Dj";
export var smallBlockLink = "home-module--smallBlockLink--bFyJx";
export var smallBlockImg = "home-module--smallBlockImg--irkRk";
export var blockContentContainer = "home-module--blockContentContainer--3Hqf8";
export var blockContent = "home-module--blockContent--3s0Tl";