import React from 'react';

import { container } from './InfoSection.module.scss';

const InfoSection = ({ children, style }) => {
  return (
    <div style={style} className={container}>
      {children}
    </div>
  );
};

export default InfoSection;
