import { useStaticQuery, graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import SwiperCore, {
  Autoplay,
  Navigation,
  Keyboard,
  A11y,
  EffectFade,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Container from 'components/Container/Container';
import IconText from 'components/IconText/IconText';
import DeliveryTruckStatic from 'components/Icons/Project/DeliveryTruckStatic';
import Quality from 'components/Icons/Project/Quality';
import Security from 'components/Icons/Project/Security';
import InfoSection from 'components/InfoSection/InfoSection';
import Layout from 'components/Layout';
import MultipleCarousel from 'components/MultipleCarousel/MultipleCarousel';
import OffersCarousel from 'components/OffersCarousel/OffersCarousel';
import PromoGrid from 'components/PromoGrid/PromoGrid';
import PromoSection from 'components/PromoSection/PromoSection';
import Seo from 'components/Seo';
import TitleBrand from 'components/TitleBrand/TitleBrand';

import useCarousel from 'data/useCarousel';
import useData from 'data/useData';

import useCart from 'hooks/useCart';

import 'swiper/swiper.scss';

import 'swiper/components/a11y/a11y.scss';
import 'swiper/components/navigation/navigation.scss';

import { hrLightFull } from 'styles/components/hr.module.scss';
import {
  btnLink,
  btnLinkDark,
  btnLinkSecondary,
} from 'styles/components/links.module.scss';
import * as typography from 'styles/components/typography.module.scss';
import * as styles from 'styles/pages/home.module.scss';

import 'styles/overrides/swiper.scss';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Keyboard, A11y, EffectFade]);

const query = graphql`
  query {
    allStrapiPromoBlock(sort: { fields: itemIndex, order: ASC }) {
      nodes {
        category {
          id
          slug
          parent
        }
        id
        title
        description
        buttonText
        relativePath
        image {
          alternativeText
          caption
          url
          hash
        }
      }
    }
  }
`;

const mbM = { marginBottom: '3.5rem' };

const IndexPage = () => {
  let {
    allStrapiPromoBlock: { nodes: blocks },
  } = useStaticQuery(query);
  const { carousel } = useCarousel();
  const { data: bestSellers } = useData('/best-sellers');
  const { totals } = useCart();
  const freeTransfer =
    totals.priceWithoutFee && totals.priceWithoutFee.split('.')[0];
  return (
    <Layout fullWidth={true}>
      <Seo title="Αρχική" />
      <div className={styles.sliderContainer}>
        <Swiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          speed={500}
          loop={true}
          spaceBetween={0}
          navigation
          keyboard
          scrollbar={{ draggable: true }}
          className="swiper-container-full"
        >
          {blocks.map((block) => (
            <SwiperSlide
              key={block.id}
              className={styles.slide}
              style={{
                backgroundImage: `url(${
                  process.env.GATSBY_API_URL + block.image[0].url
                })`,
              }}
            >
              <div className={styles.slideContent}>
                <h1 className={typography.mainHeader}>{block.title}</h1>
                <p className={typography.mainDescription}>
                  {block.description}
                </p>
                <Link className={btnLink} to={block.relativePath}>
                  {block.buttonText}
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Container>
        <InfoSection style={{ margin: '5rem auto' }}>
          <IconText
            title="ΥΨΗΛΗ ΠΟΙΟΤΗΤΑ ΥΛΙΚΩΝ"
            icon={<Quality />}
            text="σε κάθε κατασκευή μας"
            iconSize="large"
            style={{ margin: '1rem auto' }}
          />
          <IconText
            title="ΔΩΡΕΑΝ ΜΕΤΑΦΟΡΙΚΑ"
            icon={<DeliveryTruckStatic />}
            text={`για αγορές άνω των ${freeTransfer || '150'}€`}
            iconSize="large"
            style={{ margin: '1rem auto' }}
          />
          <IconText
            title="ΑΣΦΑΛΕΙΑ ΣΥΝΑΛΛΑΓΩΝ"
            icon={<Security />}
            text="για την προστασία των ηλεκτρονικών σας πληρωμών"
            iconSize="large"
            style={{ margin: '1rem auto' }}
          />
        </InfoSection>
        <hr className={hrLightFull} style={mbM} />
        <OffersCarousel
          title={<TitleBrand text="Νέα Προϊόντα" />}
          products={carousel}
        />
      </Container>
      <PromoGrid
        big={
          <>
            <div className={styles.bigBlockContent}>
              <h2 className={typography.mainHeaderDark}>Γυναικεία κοσμήματα</h2>
              <p className={typography.mainDescriptionDark}>
                Αφεθείτε να σας κερδίσει ο θαυμάσιος κόσμος της Κρητικής
                πολιτιστικής κληρονομιάς! Είστε έτοιμες; Είναι πάντα εκεί και
                σας περιμένει!
              </p>
              <h2 className={typography.simpleHeaderDark}>
                Δημοφιλείς κατηγορίες
              </h2>
              <div style={{ margin: '0 -0.25rem 2.5rem' }}>
                <Link
                  style={{ margin: '0.25rem' }}
                  className={btnLinkSecondary}
                  to="/gia-th-krhtikh-foresia/skoylarikia/"
                >
                  ΣΚΟΥΛΑΡΙΚΙΑ
                </Link>
                <Link
                  style={{ margin: '0.25rem' }}
                  className={btnLinkSecondary}
                  to="/gia-th-krhtikh-foresia/daxtylidia/"
                >
                  ΔΑΧΤΥΛΙΔΙΑ
                </Link>
                <Link
                  style={{ margin: '0.25rem' }}
                  className={btnLinkSecondary}
                  to="/gia-th-krhtikh-foresia/stayroi/"
                >
                  ΣΤΑΥΡΟΙ
                </Link>
                <Link
                  style={{ margin: '0.25rem' }}
                  className={btnLinkSecondary}
                  to="/gia-th-krhtikh-foresia/giorntania/"
                >
                  ΓΙΟΡΝΤΑΝΙΑ
                </Link>
                <Link
                  style={{ margin: '0.25rem' }}
                  className={btnLinkSecondary}
                  to="/gia-th-krhtikh-foresia/karfitses/"
                >
                  ΚΑΡΦΙΤΣΕΣ
                </Link>
              </div>
              <Link className={btnLinkDark} to="/gia-th-krhtikh-foresia/">
                ΔΕΙΤΕ ΤΑ ΟΛΑ
              </Link>
            </div>
            <figure className={styles.bigBlockFigure}>
              <StaticImage
                style={{ display: 'block' }}
                src="../images/woman.png"
                alt="Γυναικεία κοσμήματα"
              />
            </figure>
          </>
        }
        small={
          <Link
            className={styles.smallBlockLink}
            to="/gia-th-krhtikh-foresia/kolie-and-mentagion/"
          >
            <div style={{ marginTop: 'auto' }}>
              <h2 className={typography.mainHeader}>Για τη κρητική φορεσιά</h2>
              <p className={typography.mainDescription}>
                Εξερευνήστε τα τελειότερα αντιπροσωπευτικά κομμάτια μιας
                θαυμαστής πορείας μέσα στο χρόνο! Μοντέρνα και απέριττα, όπως
                και τότε!
              </p>
            </div>
            <StaticImage
              className={styles.smallBlockImg}
              src="../images/archaika.jpg"
              alt="Αρχαϊκά γυναικεία και ανδρικά κοσμήματα"
            />
          </Link>
        }
      />
      <PromoGrid
        isLeft={false}
        big={
          <>
            <div className={styles.bigBlockContentRight}>
              <h2 className={typography.mainHeaderDark}>
                Ανδρικά κοσμήματα & κρητικά μαχαίρια
              </h2>
              <p className={typography.mainDescriptionDark}>
                Υπάρχουν κάποια κομμάτια, που μιλούν στη ψυχή σας. Οπλιστείτε με
                τη δύναμη, που σας δίνουν αυτά τα κομμάτια-σύμβολα!
              </p>
              <Link
                className={btnLinkDark}
                to="/gia-th-krhtikh-foresia/andrikes-kadenes-and-mentagion/"
              >
                ΔΕΙΤΕ ΤΙΣ ΚΑΔΕΝΕΣ
              </Link>
            </div>
            <figure className={styles.bigBlockFigureHalf}>
              <StaticImage
                style={{ display: 'block' }}
                src="../images/man.png"
                alt="Ανδρικά κοσμήματα & μαχαίρια"
              />
            </figure>
          </>
        }
        small={
          <Link className={styles.smallBlockLink} to="/sygxrona-kosmhmata/">
            <div style={{ marginBottom: 'auto' }}>
              <h2 className={typography.mainHeader}>Σύγχρονα Κοσμήματα</h2>
              <p className={typography.mainDescription}>
                Κάντε τώρα τα όνειρα σας να λάμψουν, μέσα από τις συλλογές μας,
                φτιαγμένες ειδικά για τους ανθρώπους που αγαπάτε!
              </p>
            </div>
            <StaticImage
              className={styles.smallBlockImg}
              src="../images/wedding.jpg"
              alt="Κοσμήματα για γάμο και βάπτιση"
            />
          </Link>
        }
      />
      <Container>
        <MultipleCarousel
          title={
            <TitleBrand
              style={{ marginBottom: '2.5rem' }}
              text="Best Sellers"
            />
          }
          collection={bestSellers}
        />
      </Container>
      <PromoSection
        left={
          <StaticImage
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
            src="../images/kritikoi.jpg"
            alt="Η ζωντάνια της Κρητικής παράδοσης - Παραδοσιακά Κρητικά κοσμήματα"
          />
        }
        right={
          <div className={styles.blockContentContainer}>
            <div className={styles.blockContent}>
              <h2 className={typography.mainHeader}>
                Η ζωντάνια της Κρητικής παράδοσης
              </h2>
              <p className={typography.mainDescription}>
                Η ζωτική δύναμη της Κρητικής παράδοσης φλογίζει κάθε ανθρώπινη
                καρδιά. Μας εμψυχώνει όλους προς υψηλούς και γενναίους στόχους…
              </p>
              <Link className={btnLink} to="/krhtika-maxairia/">
                ΑΝΑΚΑΛΥΨΤΕ ΤΑ ΚΡΗΤΙΚΑ ΜΑΧΑΙΡΙΑ
              </Link>
            </div>
          </div>
        }
      />
    </Layout>
  );
};

export default IndexPage;
