import React from 'react';

import * as styles from './PromoGrid.module.scss';

const PromoGrid = ({ isLeft = true, big, small }) => {
  return (
    <div className={isLeft ? styles.container : styles.containerRight}>
      <div className={isLeft ? styles.big : styles.small}>
        {isLeft ? big : small}
      </div>
      <div className={isLeft ? styles.small : styles.big}>
        {isLeft ? small : big}
      </div>
    </div>
  );
};

export default PromoGrid;
