import React, { useState } from 'react';
import SwiperCore, { Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Carousel from 'components/Carousel/Carousel';

import { container } from '../OffersCarousel/OffersCarousel.module.scss';
import { category } from './MultipleCarousel.module.scss';

import 'styles/overrides/swiper-multiple.scss';

SwiperCore.use([Thumbs]);

const thumbs = {
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  freeMode: true,
  slidesPerView: 8,
  spaceBetween: 0,
  className: 'swiper-multiple-thumbs',
  allowTouchMove: false,
};

const settings = {
  spaceBetween: 50,
  slidesPerView: 1,
  className: 'swiper-multiple-container-carousel',
};

const MultipleCarousel = ({ title, collection }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  if (!collection) {
    return null;
  }
  return (
    <div className={container}>
      {title}
      <Swiper onSwiper={setThumbsSwiper} {...thumbs}>
        {collection.map((item) => (
          <SwiperSlide key={item.subCategory._id}>
            <span className={`${category}`} key={item.subCategory._id}>
              {item.subCategory.title}
            </span>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        thumbs={{
          swiper: thumbsSwiper,
        }}
        {...settings}
      >
        {collection.map((item) => (
          <SwiperSlide key={item.subCategory._id}>
            <Carousel products={item.products} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MultipleCarousel;
