import React from 'react';

import { container } from './PromoSection.module.scss';

const PromoSection = ({ left, right }) => {
  return (
    <section className={container}>
      <div>{left}</div>
      <div>{right}</div>
    </section>
  );
};

export default PromoSection;
