import useSWR from 'swr';

import fetcher from 'utilities/fetcher';

export default function useCarousel() {
  const { data, error } = useSWR(
    process.env.GATSBY_API_URL + '/carousels',
    fetcher
  );

  const loading = !data && !error;

  return {
    loading,
    error,
    carousel: data
      ? data?.sort((a, b) => a.itemIndex - b.itemIndex)
      : // .map((item) => {
        //   let url = `/${
        //     categories.find(
        //       (cat) => cat.strapiId === item.product.mainCategory
        //     ).slug + '/' || ''
        //   }${
        //     categories.find(
        //       (cat) => cat.strapiId === item.product.subCategory
        //     ).slug + '/' || ''
        //   }${item.product.slug}`;
        //   return { ...item.product, slug: url };
        // })
        data,
  };
}
